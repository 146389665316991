<template>
  <v-container class="my-3">
    <v-layout row wrap justify-space-between class="mb-5">
      <v-flex xs6 sm4 md4 lg4 class="mb-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackSuccess"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("account added successfully") }}!</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not add an account. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-layout row wrap justify-space-between class="mb-6">
      <v-flex xs6 sm4 md4 lg4>
        <h2 class="headline text-capitalize">{{ $t("new account") }}</h2>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs6 sm4 md4 lg4 class="text-right">
        <v-btn
          small
          dark
          :loading="loading"
          :color="$store.state.secondaryColor"
          @click="uploadData"
        >
          <v-icon small left>save</v-icon>
          <span class="caption text-capitalize">{{ $t("register") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-row justify="space-between">
      <v-alert>
        <v-form ref="form">
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm6 md4 lg4 dense class="caption px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("business") }}:
                </span>
                <v-text-field
                  v-if="selectedBusiness"
                  outlined
                  dense
                  v-model="selectedBusinessName"
                  readonly
                  :rules="rules.emptyField"
                ></v-text-field>

                <v-select
                  v-else
                  outlined
                  dense
                  item-text="businessName"
                  item-value="id"
                  :items="ownersBusinesses"
                  v-model="account.businessId"
                  :rules="rules.emptyField"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md4 lg4 class="caption px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("account name") }}:
                </span>
                <v-textarea
                  outlined
                  dense
                  auto-grow
                  rows="1"
                  v-model="account.accountName"
                  :rules="rules.emptyField"
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm6 md4 lg4 class="caption px-1">
                <span class="font-weight-medium text-capitalize"
                  >{{ $t("account type") }} :
                </span>
                <v-select
                  outlined
                  dense
                  item-text="account type"
                  :items="accountTypes"
                  item-value="id"
                  v-model="account.accountType"
                  :rules="rules.emptyField"
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md4 lg4 class="caption px-1">
                <span class="font-weight-medium text-capitalize">
                  {{ $t("particulars") }} :
                </span>
                <v-textarea
                  outlined
                  dense
                  auto-grow
                  rows="1"
                  v-model="account.particulars"
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm6 md4 lg4 class="caption px-1">
                <span class="font-weight-medium"
                  >{{ $t("opening balance") }}:
                </span>
                <v-text-field
                  @keypress="digits"
                  outlined
                  dense
                  v-model.trim="account.amount"
                  :rules="rules.number"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md4 lg4 class="caption px-1">
                <span class="font-weight-medium">{{ $t("date") }}: </span>
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="account.dateTime"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="account.dateTime"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      in
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="account.dateTime"
                    :max="todaysDate"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(account.dateTime)"
                    >
                      {{ $t("ok") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-alert>
    </v-row>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import { format } from "date-fns";
export default {
  data: () => ({
    todaysDate: format(new Date(), "yyyy-MM-dd"),
    snackSuccess: false,
    snackError: false,
    modal: false,
    timeout: 2000,
    loading: false,
    account: {
      accountName: "",
      accountType: "",
      businessId: "",
      particulars: "",
      amount: "",
      dateTime: "",
      ownerId: "",
    },
  }),

  computed: {
    selectedBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },

    selectedBusinessName() {
      let busId = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      let busName = busId.businessName;
      return busName;
    },
    accountTypes() {
      return [
        this.$t("assets"),
        this.$t("liabilities"),
        this.$t("expenses"),
        this.$t("owners equity"),
        this.$t("revenues"),
      ];
    },
    rules() {
      return {
        emptyField: [(v) => !!v || this.$t("this field is required")],
        number: [
          (v) => !!v || this.$t("this field is required"),
          (v) => !isNaN(v) || this.$t("please enter a number"),
        ],
      };
    },
  },
  methods: {
    uploadData() {
      //ownerid
      if (this.$refs.form.validate()) {
        this.loading = true;
        db.collection("accounts")
          .add(this.account)
          .then(() => {
            this.loading = false;
            this.snackSuccess = true;
            setTimeout(() => this.goBack(), 2000);
          })
          .catch(() => {
            this.loading = false;
            this.snackError = true;
          });
      }
    },

    digits(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 43) {
        // 46 is dot 43 is +
        event.preventDefault();
      }
    },

    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
